import logo from '../images/logo.png';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <div className="header">
      <Link to="/Home"><img src={logo} className="App-logo" alt="logo"/></Link>
    </div>
  );
}

export default Header;
