import logo from '../images/logo.png';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <img src={logo} className="App-logo" alt="logo"/>
      <ul>
        <li><Link to="/Home">HOME</Link></li>
        <li><Link to="/About">ABOUT</Link></li>
        <li><Link to="/Services">SERVICES</Link></li>
        <li><Link to="/Contact">CONTACT</Link></li>
        <li><Link to="/Careers">CAREER OPPORTUNITIES</Link></li>
        <li><Link to="/Benefits">CAREER BENEFITS</Link></li>        
     	</ul>
       <hr></hr>
      <p>&copy; 2024 <strong>Fusion 2, Inc.</strong> - All Rights Reserved.</p>
    </div>
  );
}

export default Footer;
