import services from '../images/services.png';
import './Services.css';

function Services() {
  return (
    <div className="viewWrapper">
      <img src={services}></img>
      <div className="viewContent">
        <h2>Program Management Support</h2>
        <p>Even the most critical technologies cannot support fleet needs without strong project management. Fusion 2, Inc. has assembled a program management support team with diverse and extensive experience in DoD and NAVAIR program management. The team excels being our customers trusted agent during transitioning advanced Science and Technology (S&T) efforts to fielding of Programs of Record. Our Program Management strength lies in acquisition strategies that balance cost control, schedule execution, and technical performance while delivering the right capability to the fleet before they need it.</p>
        <h2>Digital Engineering Support</h2>
        <p>Technology is ever evolving, and battle space solutions are growing in complexity. Fusion 2, Inc. believes in order to outpace the threat we must leverage the long-term benefits of Digital Engineering. The Digital Engineering team have real world experience in leading customers through transitions to an Integrated Digital Environment (IDE). We have experience implementing Digital Engineering across the complexity of multi security environments while maintaining configuration control. All direct site professionals are certified in Model Based Systems Engineering and are integrated in the implementation processes at NAVAIR and the DoD.  Primary strengths are in the establishment and architecture of the digital environment, translating the existing Systems Engineering Technical Review (SETR) process to a non-monolithic design review process, and the integration of other model-based tools to form a wholistic engineering solution.</p>
        <h2>Systems Engineering</h2>
        <p>Without a foundation of engineering rigor new solutions fall victim to the pitfalls of the past. Fusion 2, Inc. recognizes the engineering rigor of the SETR process provides safeguards for solutions to meet fleet needs in the environments where the fleet require. The company has extensive experience supporting the systems engineering process on ACAT programs of all sizes. Understanding the design maturity each step the SETR process satisfies enables the application of those processes to a digital environment with the same engineering rigor. Fusion 2, Inc. strength in Systems Engineering is in establishing systems engineering plans, requirements definition, requirements decomposition, System of system requirements allocation and risk management to support the customers’ requirements are satisfied by a robust design.</p>
      </div>
    </div>
  );
}

export default Services;
