import F35cockpit from '../images/F35HUD.png';
import './Home.css';

function Home() {
  return (
    <div className="viewWrapper">
    <img src={F35cockpit}></img>
    <div className="viewContent">
      <h2>Innovating to Challenge the Status Quo</h2>
      <p>Our team invites "why" to understand complex challenges then provides simplistic solutions to customer needs. We leverage our collective experience, bridging proven processes with digital tools.</p>

      <h2>Unparalleled Support</h2>
      <p>Customer mission success is our unwavering focus. Fusion 2, Inc. empowers our employee’s professional and personal goals, establishing an environment of creative, loyal team members that are focused on providing value where it matters most.</p>

      {/* <h2>EDWOSB</h2>
      <p>Fusion 2, Inc. is a third party certified Economically Disadvantaged Women Owned Small Business (EDWOSB) which enables us to reach our customers through traditional, set-aside, and sole source contracting options (FAR 19.1506).</p> */}
    </div>
  </div>
  );
}

export default Home;
