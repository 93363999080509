 import lrasm from '../images/lrasm.png';
import './Careers.css';

function Careers() {
  return (
    <div className="viewWrapper">
      <img src={lrasm}></img>
      <div className="viewContent">
        <p>Fusion 2, Inc. is dedicated to finding employees who are on the cutting edge of technical innovation and the best in their field. Fusion 2, Inc. is committed to their employees and will support them in all possible ways to enable them to provide accurate innovative support.</p>
        <p>Interested in joining our team? Please see below for an overview of our postings.</p>
        <h3><a href='https://www.clearancejobs.com/jobs/7776524/radar-engineer' target='_blank'>Radar Engineer</a></h3>
        <h3><a href='https://www.clearancejobs.com/jobs/7456628/software-engineer' target='_blank'>Software Engineer</a></h3>         
        <h3><a href='https://www.clearancejobs.com/jobs/7667133/systems-engineer' target='_blank'>Systems Engineer-Aero</a></h3>
        <h3><a href='https://www.clearancejobs.com/jobs/7776523/systems-engineer' target='_blank'>Systems Engineer</a></h3>
      </div>
    </div>
  );
}

export default Careers;
