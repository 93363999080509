import { Link } from 'react-router-dom';
import balance from '../images/balance.jpg';
import './Benefits.css';

function Benefits() {
  return (
    <div className="viewWrapper">
      <img src={balance}></img>
      <div className="viewContent">
      <p><strong>Fusion 2, Inc.</strong> is committed to providing employees with a comprehensive and competitive suite of benefits to promote health, well-being and financial security for employees and their families.
        We encourage employees to take time off from work on a regular basis to enjoy activities and interests outside of work. Benefits include:</p>
        <ul>
          <li>Comprehensive Health Care -Medical, Dental and Vision</li>
          <li>Retirement Plan, including an automatic Safe Harbor Contribution</li>
          <li>Paid Time Off</li>
          <li>Paid Holidays</li>
          <li>Flexible Schedule</li>
          <li>Short Term Disability</li>
          <li>Long Term Disability</li>
          <li>Term Life Insurance</li>
          <li>Accidental Death and Dismemberment Coverage</li>
          <li>Workers Compensation</li>
        </ul>
        <p>Interested in joining our team? Please go to the <Link to="/Careers">Career Opportunities Page</Link> to view our positions.</p>
      </div>
    </div>
  );
}

export default Benefits;
