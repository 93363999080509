import contact from '../images/contact.png';
import './Contact.css';
import Mailchimp from 'react-mailchimp-form'

function Contact() {
  return (
    <div className="viewWrapper">
      <img src={contact}></img>
      <div className="viewContent contact">
        <h2>To find out more about Fusion 2, Inc. and how we can serve your company please fill out the form below.</h2>
        <div className="contactDetails">
          <div className="contactform">
          <h2>Write Us:</h2>
          <Mailchimp
        action='https://gmail.us5.list-manage.com/subscribe/post?u=977c11e718e9dcc1d6764e11f&amp;id=632de5db58'
        fields={[
          {
            name: 'FNAME',
            placeholder: 'Your Full Name',
            type: 'text',
            required: true
            
          },
          {
            name: 'EMAIL',
            placeholder: 'Your Email',
            type: 'email',
            required: true
          },
          {
            name: 'SUBJECT',
            placeholder: 'Subject',
            type: 'text',
            required: true
          },
          {
            name: 'MESSAGE',
            placeholder: 'Enter Your Message Here',
            type: 'text',
            required: true
          }
        ]}
        // Change predetermined language
        messages = {
          {
            sending: "Sending...",
            success: "Thank you for contacting Fusion 2, Inc.!",
            error: "An unexpected internal error has occurred.",
            empty: "Please complete all fields.",
            duplicate: "Thank you for reaching out, we have already received your message and will be in touch soon. We appreciate your patience.",
            button: "Submit"
          }
        }
        // Add a personalized class
        // className='<YOUR_CLASSNAME>'
                
        />
          </div>
          <div className="contactInfo">
            <h2>Call us:</h2>
            <p>(240) 925-8046</p>
            {/* <p>Monday thru Friday</p>
            <p>0800 to 1700 Eastern</p> */}
            <h2>Located In:</h2>
            <p>Leonardtown, MD 20650</p>
          </div>
        </div>
      </div>      
    </div>
  );
}

export default Contact;
