import { Link } from 'react-router-dom';
import './Nav.css';

function Nav() {
  return (
    <div className="nav">
      <ul>
        <li><Link to="/About">ABOUT</Link></li>
        <li><Link to="/Services">SERVICES</Link></li>
        <li><Link to="/Contact">CONTACT</Link></li>
        <li className="dropdown">
          <span className="dropbtn">CAREERS</span>
          <ul className="dropdown-content">
            <li><Link to="/Careers">OPPORTUNITIES</Link></li>
            <li><Link to="/Benefits">BENEFITS</Link></li>
          </ul>
        </li>
     	</ul>
    </div>
  );
}

export default Nav;