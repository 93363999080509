import React from 'react';
import  Header from './components/Header';
import  Nav  from './components/Nav';
import Footer from './components/Footer';
import  Home  from './views/Home';
import About from './views/About';
import Contact from './views/Contact';
import Services from './views/Services';
import Careers from './views/Careers';
import Benefits from './views/Benefits';
import { Route, Switch, Redirect } from 'react-router-dom';

export const Routes = () => {
  return (
    <div>
      <Header />
      <Nav />
      <Switch>
        <Route exact path="/Home" component={Home} />
        <Route exact path="/">
          <Redirect to="/Home" />
        </Route>
      </Switch>
      <Switch>
        <Route exact path="/About" component={About} />
      </Switch>
      <Switch>
        <Route exact path="/Contact" component={Contact} />
      </Switch>
      <Switch>
        <Route exact path="/Services" component={Services} />
      </Switch>
      <Switch>
          <Route exact path="/Careers" component={Careers} />
      </Switch>
      <Switch>
          <Route exact path="/Benefits" component={Benefits} />
      </Switch>
      <Footer />
    </div>
  );
};