import about from '../images/aboutUs.png';
import culture from '../images/culture.png';
import values from '../images/values.png';
import './About.css';

function About() {
  return (
    <div className="viewWrapper">
      <img src={about}></img>
      <div className="viewContent">
        <h2>What We Value</h2>
        <div className="valuesDiv">
          <p className="valuesImg"><img src={values}></img></p>
          <p className="valuesText">The <strong>PURPOSE</strong> of Fusion 2, Inc. is to provide unwavering commitment to our customers, delivering innovative solutions to meet mission objectives. We are a collection of industry professionals dedicated to the advancement of the US Department of Defense capabilities. Our experience spans from technology inception at the Science and Technology (S&T) phase, through the transition to ACAT development program, production, and beyond into sustainment. This vast DoD experience over the programmatic lifecycle gives us foresight when supporting customers as we pace the evolving battle space.</p>
        </div>
        <h2>Where We Focus</h2>
        <p>The <strong>VISION</strong> of Fusion 2, Inc. is to be the collection of experts our customers see as mission critical; to orchestrate the rapid transition of critical technology to the hands of the warfighter. Our <strong>MISSION</strong> is to support the development and employment of the next generation of defense systems by leveraging the latest digital tools, and technical program management techniques while preserving proven fundamentals.</p>

        <h2>How We Create Culture</h2>
        <p>Fusion 2, Inc. Leadership believes that to build and maintain a high functioning company we must empower our employees. We do this by providing competitive tools, compensation, and benefits that allow their focus to be on what they do best. We strive to provide clear expectations, so employees have the flexibility to balance time between customer challenges and the importance of family and self.</p>
        <p className="cultureImg"><img src={culture}></img></p>
    </div>
    </div>
  );
}

export default About;
